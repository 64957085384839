import _ from "underscore";
import moment from "moment";
import {hidePolicyLabelCompanyIdList} from "@/lib/other/index.js";
// 酒店详情
import consumer_hotel_search_getRoomPackageInfo from "@/lib/data-service/haolv-default/consumer_hotel_search_getRoomPackageInfo.js";
// 出差单员工
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail.js";
// 酒店创建预订单
import consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness.js";
//酒店因私创建预订单
import consumer_t_od_hotel_order_interim_addOrUpdateOnPerson from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_addOrUpdateOnPerson.js";
// 退订规则
import req_refund_rule from "@/lib/data-service/haolv-default/consumer_hotel_search_getRatePlan.js";
// 【1335】酒店详情获取图片列表(清洗后数据)
import consumer_hotel_search_hotelImg from "@/lib/data-service/haolv-default/consumer_hotel_search_hotelImg.js";
//【1335】酒店信息(清洗后数据)
import consumer_hotel_search_hotelInfo from "@/lib/data-service/haolv-default/consumer_hotel_search_hotelInfo.js";
//【1335】酒店详情(清洗后数据)
import consumer_hotel_search_hotelDetails from "@/lib/data-service/haolv-default/consumer_hotel_search_hotelDetails.js";
//选择时间组件
import HotelStayDatePicker from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-stay-date-picker/import_latest_version_module';
// 超标检测弹窗
import CheckTravel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/check-travel/1.0.0/index.vue'
//每天报价
import DailyPrice from './components/daily-price/1.0.0/index.vue';
//地图
import Map from '@/component/map/1.0.0/index.vue';

import EvectionStandard from '@/component/evectionStandard/import_latest_version_module'
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.1.1'//获取出差类型
import refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get'
import getJourneyOptionsCanBeModified from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import TravelPopup from '@/component/travel-popup/1.0.0/index.vue'

import aes_decrypt from 'nllib/src/common/aes/aes_decrypt';
import aes_encrypt from "nllib/src/common/aes/aes_encrypt";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";

export default {
    components: {
        HotelStayDatePicker,
        CheckTravel,
        DailyPrice,
        Map,
        EvectionStandard,
        EvectionStandardExceed,
        TravelPopup
    },
    data() {
        var validteCheckIn = (rule, value, callback) => {
            if (
                !value.length ||
                value === "" ||
                value === null ||
                value === undefined
            ) {
                callback(new Error("请选择入住人"));
            } else {
                callback();
            }
        };

        return {
            hotelInfo: {},
            hotelDetails: {},
            loading: true,
            loadingImg: true,
            companyId: 0,
            isShowStandard: true,
            dialogError2Visible: false,
            errorText: '',
            workTravelType: '',
            journeyType: 0,
            hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
            hotelStaticInfo: {},
            hotelImgList: [],
            emptyHotelImgList: [1, 2, 3, 4, 5, 6],
            roomType: 1,
            unlimitedJourney: 1, // 是否不限行程 0：否 1：是
            // 请求房型参数
            searchForm: {
                // 出差单号
                evectionNo: "",
                checkInDate: "",
                checkOutDate: "",
                hotelId: "",
            },

            // 预定表单
            reserveForm: {
                cityName: "",
                // 入住时间
                checkInDate: "",
                // 离店时间
                checkOutDate: "",
                // 城市id
                cityId: "",
                // 出差单号
                evectionNo: "",
                // 酒店地址
                hotelAddr: "",
                // 酒店id
                hotelId: "",
                // 酒店名称
                hotelName: "",
                // 房型id
                hotelRoomTypeId: "",
                // 房型名称
                hotelRoomTypeName: "",
                hotelFrom: '',//酒店来源
                keyId: "",
                hotelStar: "",
                hotelPhone: "",
                hotelPicUrl: "",
                // 订单号
                orderNo: "",
                // 价格
                price: "",

                coseList: "",
                // 入住晚数
                stayNights: 0,
                // 间夜信息
                nightlyRateList: null,
                // 第三方id
                thirdpartyHotelId: "",
                breakfast: '',
                journeyId: '',

                countryName: '中国',//国家名称
                stateName: '',//省份名称
                areaName: '',//区县名称
                windowName: '',//窗户说明
                breakfastName: '',//早餐说明
                overproofApplyList: [],

                groupName: '',//酒店集团
                negotiatedPriceText: ''//协议价
            },

            noticeVisible: false,

            noticeDatas: {
                bookingRule: [],
                refundRule: [],
            },
            // 酒店详情
            detail: {},
            // 房型列表
            roomList: [],
            // 控制没有房型是的显示
            isEmpty: false,
            // 出差单内员工
            travellerList: [],
            // 入驻时间选择
            dateRange: null,
            timeOptionRange: null,
            // 时间框选择范围
            hotelPickerOptions: {
                disabledDate: this.hotelDisabledDate,
                onPick: this.hotelOnPick
            },
            exceedVisible: false,

            overproof: {
                evectionNo: "",
                overproofApplyList: [],
            },
            evectionRequired: 0,// 出差单是否必填 出差单是否必填：0:否, 1:是

            evectionDetailType: '001',
            evectionType: 1,
            timeCanEdit: true,
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            startPickerOptions: {
                disabledDate: this.disabledCheckInDate
            },
            showNotBookDialog: false,
            today: '',//今天-'YYY-MM-DD'
            yesterday: '',//昨天-'YYY-MM-DD'
            isMorning: false,//当前时间是否为凌晨00:00-06:00
            isTodayMorning: false,//是否今天凌晨入住

            shareEvectionNo: '',
            shareJourneyId: 0,

            standardBox: false,
            breakfastBox: false,
            windowBox: false,
            cancelBox: false,
            protocolBox: false,

            popularList: [
                {
                    type: 'standardBox',
                    name: '符合差标',
                },
                {
                    type: 'breakfastBox',
                    name: '含早餐',
                },
                {
                    type: 'windowBox',
                    name: '有窗',
                },
                {
                    type: 'cancelBox',
                    name: '免费取消',
                },
                {
                    type: 'protocolBox',
                    name: '协议酒店',
                },
            ],
        };
    },
    computed: {},
    methods: {
        refundRuleHandle(text) {
            let match = text.match(/\[(.*?)\]/);
            let StringBox = match ? match[1] : text;
            return StringBox;
        },
        iconfontBox(label) {
            let nameBox = 'haolv-iconconvenient';

            if (label == '安全与安保') {
              nameBox = 'haolv-iconsecure';
            }
            if (label == '餐饮服务') {
              nameBox = 'haolv-iconfoot';
            }
            if (label == '儿童设施服务') {
              nameBox = 'haolv-iconchildren-facilities';
            }
            if (label == '公共区') {
              nameBox = 'haolv-icontotal1';
            }
            if (label == '客房设施') {
              nameBox = 'haolv-iconbad1';
            }
            if (label == '其他服务') {
              nameBox = 'haolv-iconsport';
            }
            if (label == '前台服务') {
              nameBox = 'haolv-iconfront-servce';
            }
            if (label == '清洁服务') {
              nameBox = 'haolv-iconclean';
            }
            if (label == '商务服务') {
              nameBox = 'haolv-iconbox-edit';
            }
            if (label == '娱乐活动设施') {
              nameBox = 'haolv-iconentertainment';
            }
            if (label == '便利设施') {
              nameBox = 'haolv-iconconvenient';
            }
            if (label == '康体设施') {
              nameBox = 'haolv-iconfitness1';
            }
            if (label == '特色设施') {
              nameBox = 'haolv-iconcharacteristic';
            }
            if (label == '电竞设施') {
              nameBox = 'haolv-iconesports';
            }
            if (label == '卫浴设施服务') {
              nameBox = 'haolv-iconbathroom';
            }
            if (label == '室外景观') {
              nameBox = 'haolv-iconlandscape';
            }
            if (label == '无障碍设施服务') {
              nameBox = 'haolv-iconaccessibility';
            }
            if (label == '交通服务') {
              nameBox = 'haolv-icontraffic';
            }
            if (label == '厨房用品') {
              nameBox = 'haolv-iconkitchen';
            }
            if (label == '媒体科技') {
              nameBox = 'haolv-iconscience';
            }

            return nameBox;
        },
        onFilter(text) {
            eval(`this.${text} = !this.${text}`);
        },
        filterShowAllTwo(roomList) {
            let typeBox = false;
            let box = [];
            roomList.forEach((item,index) => {
                if (this.filterShowAll(item.ratePlanViewList).length == 0) {
                    box.push(0);
                }
            });

            if (box.length == roomList.length) {
                typeBox = true;
            }

            return typeBox;
        },
        selectedBox(type) {
            let box = false;
            eval(`box = this.${type} == true`);
            return box;
        },
        clickClear(){
            this.standardBox = false;
            this.breakfastBox = false;
            this.windowBox = false;
            this.cancelBox = false;
            this.protocolBox = false;
        },
        filterShowAll(list) {
            let listBox = list;
            if (this.standardBox == true) {
                listBox = listBox.filter(item => item.accord == 0  && item.platformPrice > 0);
            }
            if (this.breakfastBox == true) {
                listBox = listBox.filter(item => item.breakfast > 0);
            }
            if (this.windowBox == true) {
                let windowAll = ['有窗','落地窗','外窗','内窗','部分无窗','部分有窗','大部分有窗'];
                listBox = listBox.filter(item => windowAll.indexOf(item.windowName) != -1);
            }
            if (this.cancelBox == true) {
                listBox = listBox.filter(item => item.refundRuleType == 2);
            }
            if (this.protocolBox == true) {
                listBox = listBox.filter(item => item.negotiatedPriceText == '协议酒店');
            }
            return listBox;
        },
        dateFormat(time) {
            if (`${time}` == 'null' || time == undefined || time == 'undefined') {
                return '';
            } else {
                return `${time.slice(0,4)}年${time.slice(-2)}月`;
            }
        },
        //凌晨时进入预订页面需要做的事情
        initDateForMorning() {
            this.today = this.$moment().format('YYYY-MM-DD');
            this.yesterday = this.$moment().add(-1,'days').format('YYYY-MM-DD');
            this.getIsTodayMorning();
        },

        disabledCheckInDate(time) {
            //没有选中时间（初始化状态）
            if (this.isMorning) {
                //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                let timestamp = yesterday.getTime();
                return time.getTime() < (timestamp - 8.64e7);
            } else {
                //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                return time.getTime() < (Date.now() - 8.64e7);
            }
        },

        //获取用户信息
        async getUserInfo() {
            let res = await get_user_info();
            this.companyId = res.datas.companyId;
            this.isShowStandard = _.indexOf(hidePolicyLabelCompanyIdList, this.companyId) > -1 ? false : true;
        },
        //获取出差类型
        async getEvectionType() {
            let evectionParams = {
                vm: this,
                situation: '004'
            }
            const evectionQuery = await getEvectionAllType(evectionParams)
            this.evectionDetailType = evectionQuery.evectionDetailType;
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.businessRuleControl = res.datas.businessRuleControl;
            this.unlimitedJourney = res.datas.unlimitedJourney;
        },
        canChangeFactor() {
            return getJourneyOptionsCanBeModified({
                vm: this
            }).then(res => {
                this.timeCanEdit = res.timeCanEdit
            })
        },
        setStandard() {
            if (this.evectionDetailType!=='101'&&!this.$refs.standard) {
                setTimeout(() => {
                    this.setStandard()
                }, 500)
                return
            }
            if(this.evectionDetailType!=='101') {
                this.$refs.standard.init({
                    evectionNo: this.$route.query.evectionNo,
                    businessType: 3,
                    hotelCityId: this.searchForm.cityId
                })
            }
        },
        validteCheckIn(rule, value, callback) {
            if (
                !value.length ||
                value === "" ||
                value === null ||
                value === undefined
            ) {
                callback(new Error("请选择入住人"));
            } else {
                callback();
            }
        },
        //获取酒店基础信息，包含图片
        async getHotelInfoAndImage() {
            // let res = await consumer_hotel_search_getHotelInfoAndImage({hotelId: Number(this.detail.hotelId)});
            // this.detail = {
            //     ...this.detail,
            //     phone: res.data.phone,
            //     hotelName: res.data.hotelName,
            //     longitude: res.data.longitude,
            //     latitude: res.data.latitude,
            //     imageUrl: res.data.imageEntities[0].url
            // }
            // this.reserveForm = {
            //     ...this.reserveForm,
            //     hotelPhone: res.data.phone,
            //     hotelName: res.data.hotelName,
            //     longitude: res.data.longitude,
            //     latitude: res.data.latitude,
            //     hotelPicUrl: res.data.imageEntities[0].url
            // }
            // this.$forceUpdate();
        },

        //获取是否今天凌晨入住
        getIsTodayMorning() {
            let checkInDateStr = this.$moment(this.dateRange[0]).format('YYYY-MM-DD');
            this.isTodayMorning = checkInDateStr === this.yesterday;
        },

        // 请求房型
        async req_detail(params, type) {
            this.loading = true;
            if (type != 'search') {
                this.loadingImg = true;
            }
            this.roomList = [];
            this.isEmpty = false;

            if (this.evectionDetailType !== '001') {
                params.checkInDate = this.$moment(this.dateRange[0]).format('YYYY-MM-DD');
                params.checkOutDate = this.$moment(this.dateRange[1]).format('YYYY-MM-DD');
            }

            let res = await consumer_hotel_search_hotelInfo({hotelId: params.hotelId});
            if (res.code == '000000') {
                this.hotelInfo = res.datas;
                this.hotelImgList[0] = {
                    url: this.hotelInfo.mainImage,
                    smallUrl: this.hotelInfo.mainSmallImage ? this.hotelInfo.mainSmallImage : this.hotelInfo.mainImage,
                    isSelected: false,
                };
                if (type != 'search') {
                    this.$refs.aMap.init({
                        center: [Number(this.hotelInfo.lng), Number(this.hotelInfo.lat)]
                    });
                }
            }

            if (type != 'search') {
                consumer_hotel_search_hotelDetails({hotelId: params.hotelId}).then((res) => {
                    this.hotelDetails = res.datas;
                }).finally(res => {

                });
            }

            consumer_hotel_search_getRoomPackageInfo(params)
                .then((res) => {
                    const _this = this;
                    //--------------------------------------
                    let query = _this.$route.query;
                    const params = aes_decrypt({
                      data: query.params,
                      key: 'params',
                    });
                    query = Object.assign({}, query, JSON.parse(params));
                    this.detail = query;
                    query.minPrice = res.datas.roomViewList[0].platformPrice;
                    const encrypted = aes_encrypt({
                      data: JSON.stringify(query),
                      key: 'params',
                    });
                    let paramsBox = new URLSearchParams(window.location.search);
                    paramsBox.set('params', encrypted); // 设置或更新参数
                    window.history.replaceState({}, '', `${window.location.pathname}?${paramsBox.toString()}`);
                    //--------------------------------------
                    this.detail.hotelDays = res.datas.hotelDays;
                    this.hotelStaticInfo = res.datas.hotelStaticInfo;
                    const list = res.datas.roomViewList;
                    list.forEach((item) => {
                        item.isFold = true
                        this.$set(item, 'isAgreementPrice', false)
                        item.ratePlanViewList.forEach((value) => {
                            value.hotelParams = {
                                cityId: _this.searchForm.cityId,
                                evectionNo: _this.$route.query.evectionNo,
                                price: value.averagePrice
                            }
                        })
                    });
                    this.roomList = list;
                    if (!this.roomList.length) this.isEmpty = true;
                    if (this.$route.query.evectionNo)
                        this.req_staff({
                            evectionNo: this.reserveForm.evectionNo,
                            pageType: 1,
                            personType: 2,
                        });
                    if (this.evectionDetailType === '001') {
                        this.$refs['hotelStayDatePicker'].init({
                            __this: this,
                            mode: '200',
                            applyJourneyId: this.reserveForm.journeyId,
                            timeCanEdit: this.timeCanEdit
                        })
                    }
                    if(this.evectionDetailType === '101'){
                        this.detail = {
                            ...this.detail,
                            cityId: this.hotelInfo.cityId,
                            // cityName: this.hotelInfo.name,
                            // bizName: this.hotelStaticInfo.bizName,
                            bizName: '',
                            hotelAddr: this.hotelInfo.address,
                            star: this.hotelInfo.starType,
                        }
                        this.reserveForm = {
                            ...this.reserveForm,
                            hotelStar: this.hotelInfo.starType,
                            hotelAddr: this.hotelInfo.address,
                        }
                    }
                    this.loading = false;
                    this.loadingImg = false;
                })
                .catch((err) => {
                    if (err.code === '900' || (err.data && err.data.code === '340002')) {
                        this.errorText = err.data.msg;
                        this.dialogError2Visible = true
                    }
                    this.isEmpty = true;
                    this.loading = false;
                    this.loadingImg = false;
                });
        },
        // 请求员工
        req_staff(params) {
            this.travellerList = [];
            consumer_journey_getEvectionDetail(params)
                .then((res) => {
                    const detail = res.datas;
                    this.travellerList = detail.travellerList;
                })
                .catch((err) => {
                });
        },
        //获取酒店图片
        async getHotelImg(hotelId) {
            let res = await consumer_hotel_search_hotelImg({hotelId: this.searchForm.hotelId});
            let box = [];
            res.datas.forEach(item => {
                if (item.label == '外景') {
                    box = item.list.concat(box);
                } else {
                    box = box.concat(item.list);
                }
            });
            this.hotelImgList = box.slice(0,6);

            this.hotelInfo.mainImage = this.hotelImgList[0].url;
            this.hotelInfo.mainSmallImage = this.hotelImgList[0].smallUrl;
            // box.forEach((item,index) => {
            //     if (this.hotelImgList.length <= 5) {
            //         this.hotelImgList.push({
            //             url: item.url,
            //             smallUrl: item.smallUrl,
            //             isSelected: false,
            //         });
            //     }
            // });

            // if (this.hotelImgList.length <= 5) {
            //     for(let i=0; i < 6; i++) {
            //         if (this.hotelImgList.length <= 5) {
            //             this.hotelImgList.push({
            //                 url: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/06/error-image.png',
            //                 smallUrl: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/06/error-image.png',
            //                 isSelected: false,
            //             })
            //         }
            //     }
            // }
        },
        req_person_reserve() {
            const loading = this.$loading({
                lock: true,
                text: "请求中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let params = {...this.reserveForm};
            consumer_t_od_hotel_order_interim_addOrUpdateOnPerson(params)
                .then((res) => {
                    loading.close();
                    this.$router.push({
                        name: 'admin-hotel-private-reserve',
                        query: {
                            orderNo: res.msg,
                        },
                    });
                })
                .catch((err) => {
                    loading.close();
                    console.error(err);
                });
        },
        req_reserve() {
            const loading = this.$loading({
                lock: true,
                text: "请求中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let params = {...this.reserveForm};
            consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness(params)
                .then((res) => {
                    loading.close();
                    this.$router.push({
                        name: "admin-hotel-reserve",
                        query: {
                            journeysId: this.$route.query.journeysId ? this.$route.query.journeysId : '',
                            evectionNo: res.msg,
                        },
                    });
                })
                .catch((err) => {
                    loading.close();
                    console.error(err);
                });
        },

        to_home() {
            this.$router.push({name: 'admin-travel-book-home'});
        },

        back() {
            if (this.detail.evectionType == 1) {
                this.$router.go(-1)
            } else {
                let detail = this.detail;

                let queryParams = {
                    cityId: detail.cityId,
                    cityName: detail.cityName,
                    checkInDate: detail.checkInDate,
                    checkOutDate: detail.checkOutDate,
                    departTime: detail.departTime,
                    returnTime: detail.returnTime,
                    applyId: detail.applyId,
                    journeyId: detail.journeyId
                };
                const encryptedToBack = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key: 'params',
                });

                let urlParamsToBack = {
                    params: encryptedToBack,
                    evectionNo: detail.evectionNo,
                    evectionType: detail.evectionType, // 1是个人出差，2是出差单出差
                    evectionRequired: parseInt(detail.evectionRequired) || 0,
                    workTravelType: detail.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                    journeyType: detail.journeyType, // 0 普通单， 1 不限行程单
                }

                this.$router.replace({
                    name: 'admin-hotel-search',
                    query: urlParamsToBack,
                })
            }
        },

        checkHotelPolicy() {
            let loading
            const _this = this;
            const reserveForm = this.reserveForm;
            const businessParams = {
                cityId: reserveForm.cityId,
                evectionNo: reserveForm.evectionNo,
                price: reserveForm.price
            };
            if(this.evectionDetailType!=='101') {
                this.$refs.checkTravelBox.check({
                    businessType: 3, // 机票1，火车票2，酒店3
                    businessParams: businessParams//校验差标的接口需要的入参对象
                }).then(res => {
                    loading = this.$loading({
                        lock: true,
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let params = {...this.reserveForm, ...{overproofApplyList: res.policies}};
                    consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness(params).then(res => {
                        loading.close();
                        _this.$router.push({
                            name: "admin-hotel-reserve",
                            query: {
                                journeysId: _this.$route.query.journeysId ? _this.$route.query.journeysId : '',
                                evectionNo: _this.reserveForm.evectionNo,
                                redirectType: _this.redirectType,
                                shareEvectionNo: _this.shareEvectionNo,
                                shareJourneyId: _this.shareJourneyId,
                            }
                        });
                    }, error => {
                        loading.close();
                        // 根据code返回弹窗信息
                        this.setErrorDialog(error.data)
                    }).catch(() => {
                        loading.close();
                    })
                }).catch(err => {
                    // 点取消或者不予许预定返回
                    console.log(err)
                    if (err) {
                        this.$router.go(-1) // 2021-11-13 要求返回上一级
                    }
                })
            }
        },
        // 初始化
        async init(query) {
            this.detail = query;
            this.shareEvectionNo = query.shareEvectionNo;
            this.shareJourneyId = parseInt(query.shareJourneyId || 0);
            this.evectionRequired = query.evectionRequired === undefined ? 0 : parseInt(query.evectionRequired);

            this.searchForm = {
                evectionNo: query.evectionNo,
                cityId: query.cityId,
                // applyId: query.applyId,
                hotelId: query.hotelId,
                checkInDate: query.checkInDate,
                checkOutDate: query.checkOutDate,
            };

            const form = this.reserveForm;

            form.cityId = query.cityId || "";
            form.cityName = query.cityName || "";
            form.evectionNo = query.evectionNo;
            form.hotelAddr = query.hotelAddr;
            form.hotelId = query.hotelId;
            form.hotelName = query.hotelName;
            form.orderNo = query.orderNo || "";
            form.hotelPicUrl = query.imageUrl;
            form.hotelStar = query.star;
            form.hotelPhone = query.phone;
            form.journeyId = query.journeyId;

            this.workTravelType = query.workTravelType;
            this.journeyType = query.journeyType || 0;

            this.getHotelImg(form.hotelId);
            this.dateRange = [query.checkInDate, query.checkOutDate];
            this.req_detail(this.searchForm, "init");

            if (Number(this.detail.evectionType) === 1) {
                this.popularList = [
                    {
                        type: 'breakfastBox',
                        name: '含早餐',
                    },
                    {
                        type: 'windowBox',
                        name: '有窗',
                    },
                    {
                        type: 'cancelBox',
                        name: '免费取消',
                    },
                    {
                        type: 'protocolBox',
                        name: '协议酒店',
                    },
                ];
                await this.getHotelInfoAndImage();
            }
        },
        close_notes() {
            this.noticeVisible = false;
            this.noticeDatas = {
                bookingRule: [],
                refundRule: [],
            };
        },
        popup_notes(item, combo) {
            // return console.log(item, combo)
            const detail = this.detail;
            req_refund_rule({
                params: {
                    checkInDate: this.searchForm.checkInDate,
                    checkOutDate: this.searchForm.checkOutDate,
                    hotelId: combo.hotelId,
                    keyId: combo.keyId,
                    roomTypeId: combo.roomTypeId,
                },
            }).then((res) => {
                this.noticeDatas.bookingRule = res.bookingRule;
                this.noticeDatas.refundRule = res.refundRule;
                this.noticeVisible = true;
            });
        },
        // 预定按钮事件
        to_reserve(combo, thirdpartyHotelId, roomTypeId, item) {
            // if (
            //   combo.name.indexOf("三人房") !== -1 ||
            //   combo.name.indexOf("三人") !== -1 ||
            //   combo.name.indexOf("三房") !== -1
            // ) {
            //   this.roomType = 3;
            // } else if (
            //   combo.name.indexOf("四人房") !== -1 ||
            //   combo.name.indexOf("四人") !== -1 ||
            //   combo.name.indexOf("四房") !== -1
            // ) {
            //   this.roomType = 4;
            // }

            if (this.isTodayMorning) {
                let curTimeList = this.$moment().toArray();
                if(curTimeList[3] >= 6) {
                    this.showNotBookDialog = true;
                    return;
                }
            }

            if ([2,3].indexOf(combo.isReserve) > -1) {
                this.$refs['travelPopup'].init({
                    popupType: '001',
                    evectionNo: this.reserveForm.evectionNo,
                    evectionRequired: parseInt(this.detail.evectionRequired) || 0,
                    journeyId: parseInt(this.reserveForm.journeyId) || 0,
                    businessRuleControl: this.businessRuleControl,
                    hotelInfo: {
                        cityId: this.detail.cityId,
                        checkInTime: this.detail.checkInDate,
                        checkOutTime: this.detail.checkOutDate,
                        shareJourneyId: this.detail.shareJourneyId,
                        shareEvectionNo: this.detail.shareEvectionNo,
                    },
                });
                return
            }

            this.roomType = 1;

            const travellerList = this.travellerList;
            const checkInNum = travellerList.length;

            const canReserve = combo.nightlyRateList.map((item) => item.date);
            const length = canReserve.length;
            const form = this.searchForm;
            const start = form.checkInDate;
            const clearDays = moment(form.checkOutDate).diff(moment(start), "days");

            let incomplete = false;

            for (let i = 0; i < clearDays; i++) {
                const need = moment(start)
                    .add(i, "days")
                    .format("YYYY-MM-DD");
                if (!canReserve.includes(need)) incomplete = true;
            }
            let node = "";

            if (length === 0) {
            } else if (length === 1) {
                node =
                    "当前仅“" +
                    `<span style="display: inline-block;">${canReserve[0]}</span>”${length}天可预定！`;
            } else if (length === 2) {
                node =
                    "仅“" +
                    `<span style="display: inline-block;">${canReserve[0]}</span>，<span style="display: inline-block;">${canReserve[1]}</span>”，${length}天可预定!`;
            } else {
                for (let i = 0; i < canReserve.length; i++) {
                    const item = canReserve[i];
                    if (i === 0) {
                        node =
                            "当前仅“" +
                            `<span style="display: inline-block;">${item}，</span>`;
                    } else if (i === length - 1) {
                        node += `<span style="display: inline-block;">${item}，</span>”，${length}天可预定！`;
                    } else {
                        node += `<span style="display: inline-block;">${item}，</span>`;
                    }
                }
            }

            const _this = this;
            if (incomplete) {
                this.$confirm(`${node}`, `提示`, {
                    dangerouslyUseHTMLString: true,
                    showConfirmButton: false,
                    cancelButtonText: "关闭",
                })
                    .then(() => {
                        reserve(_this);
                    })
                    .catch(() => {
                    });
            } else {
                reserve(_this);
            }

            function reserve(_this) {
                const searchForm = _this.searchForm;
                const form = _this.reserveForm;
                form.hotelId = combo.hotelId;
                form.checkInDate = searchForm.checkInDate;
                form.checkOutDate = searchForm.checkOutDate;

                form.hotelRoomTypeId = roomTypeId;
                form.hotelRoomTypeName = combo.name;
                form.stayNights = length;
                form.price = combo.cose / length;
                form.coseList = combo.coseList;
                form.thirdpartyHotelId = thirdpartyHotelId;
                form.keyId = combo.keyId;
                form.additionalPrice = item.additionalPrice; // 平台-附加价格
                form.breakfast = combo.breakfast

                form.countryName = '中国';
                form.stateName = _this.hotelInfo.provinceName;
                form.cityId = _this.hotelInfo.cityId;
                form.cityName = _this.$route.query.cityName;
                form.areaName = _this.hotelInfo.areaName;
                form.windowName = combo.windowName;
                form.breakfastName = combo.breakfastName;
                form.nightlyRateList = combo.nightlyRateList
                form.ifInvoice = combo.ifInvoice;
                form.orderFromType = combo.hotelFrom;
                form.groupName = combo.groupName;
                form.negotiatedPriceText = combo.negotiatedPriceText;
                form.refundRule = combo.refundRule;
                form.refundRuleType = combo.refundRuleType;
                form.hotelFrom = combo.hotelFrom;
                form['rateTypeId'] = combo['rateTypeId'];
                _this.commit_reserve();
            }
        },
        commit_reserve() {
            const type = this.detail.evectionType.toString();
            if (type === "1") {
                this.req_person_reserve();
            } else if (type === "2") {
                this.checkHotelPolicy();
            }
        },
        format_weeks(val) {
            if (val) {
                return val
                    .split(",")
                    .map((item) => {
                        switch (Number(item)) {
                            case 1:
                                return "星期一";
                            case 2:
                                return "星期二";
                            case 3:
                                return "星期三";
                            case 4:
                                return "星期四";
                            case 5:
                                return "星期五";
                            case 6:
                                return "星期六";
                            case 7:
                                return "星期天";
                        }
                    })
                    .join();
            }
        },
        checkEvectionRequired() {
            this.$message({
                type: "warning",
                message: '不能修改出差单既定行程'
            })
        },
        blurHotelDate() {
            this.timeOptionRange = null
        },
        hotelDisabledDate(time) {
            if (this.isMorning) {
                //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                let timestamp = yesterday.getTime();
                let isCanSelectYesterday = this.yesterday === this.startRangeDate;
                isCanSelectYesterday = true
                if (isCanSelectYesterday) {
                    return time.getTime() < (timestamp - 8.64e7) || time.getTime() > moment(this.detail.returnTime).valueOf();
                } else {
                    return time.getTime() < moment(this.detail.departTime).valueOf() || time.getTime() > moment(this.detail.returnTime).valueOf();
                }
            } else {
                //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                return time.getTime() < moment(this.detail.departTime).valueOf() || time.getTime() > moment(this.detail.returnTime).valueOf();
            }
        },
        hotelOnPick({maxDate, minDate}) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        changeBigImg(item) {
            this.hotelImgList.forEach(img => {
                img.isSelected = false;
            });
            item.isSelected = true;
            this.detail.imageUrl = item.url;
            this.hotelInfo.mainImage = item.url;
        },
        toEditEvectionInfo(popupType) {
            this.$refs['travelPopup'].init({
                popupType,
                evectionNo: this.detail.evectionNo,
                evectionRequired: parseInt(this.detail.evectionRequired) || 0,
                journeyId: parseInt(this.detail.journeyId) || 0,
                businessRuleControl: this.businessRuleControl,
                hotelInfo: {
                    cityId: this.detail.cityId,
                    checkInTime: this.detail.checkInDate,
                    checkOutTime: this.detail.checkOutDate,
                    shareJourneyId: this.detail.shareJourneyId,
                    shareEvectionNo: this.detail.shareEvectionNo,
                },
            })
        },
        updateEvectionInfo(val) {
            let curTravel;
            let urlParams = this.$route.query;
            let params = JSON.parse(aes_decrypt({
                data: urlParams.params,
                key: 'params',
            }));
            // urlParams = Object.assign({}, urlParams, JSON.parse(params))
            this.shareEvectionNo = val.shareEvectionNo;
            this.shareJourneyId = val.shareJourneyId;
            if (val.travelCurrent) {
                curTravel = val.travelCurrent.active;
                //因公，有出差单
                //改变城市时，去掉关于关键字的相关搜索条件
                if (Number(curTravel.toCityId) !== Number(params.cityId)) {
                    params.filterType = 0;
                    params.keyword = '';
                    params.pt = '';
                }
                params.applyId = val.travelCurrent.id;
                params.journeyId = curTravel.id;
                params.evectionStartDate = curTravel.departTime;
                params.evectionEndDate = curTravel.returnTime;
                if(Number(params.applyId) !== Number(val.travelCurrent.id)) {
                    params.checkInDate = curTravel.firstDepartTime;
                    params.checkOutDate = curTravel.firstReturnTime;
                } else {
                    params.checkInDate = this.searchForm.checkInDate;
                    params.checkOutDate = this.searchForm.checkOutDate;
                }
            } else {
                //因公，无出差单
                //无出行时间范围
                params.applyId = 0;
                params.journeyId = '';
                params.checkInDate = this.searchForm.checkInDate;
                params.checkOutDate = this.searchForm.checkOutDate;
                this.$delete(params, 'evectionStartDate');
                this.$delete(params, 'evectionEndDate')
            }
            params.shareJourneyId = val.shareJourneyId;
            params.shareEvectionNo = val.shareEvectionNo;

            urlParams.evectionNo = val.evectionNo;
            urlParams.workTravelType = val.workTravelType;
            urlParams.journeyType = val.journeyType;
            urlParams.unlimitedJourney = this.unlimitedJourney;
            urlParams.shareJourneyId = val.shareJourneyId;
            urlParams.shareEvectionNo = val.shareEvectionNo;

            const encrypted = aes_encrypt({
                data: JSON.stringify(params),
                key: 'params',
            });
            let p = {
                params: encrypted,
            }
            urlParams = Object.assign({}, urlParams, p)

            // 组装当前页面url
            let href = location.href.split('?')[0];
            let newUrl = href + '?';
            for(let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl);

            if (val.editType === '000') {
                let queryParams = {
                    cityId: curTravel.toCityId,
                    cityName: curTravel.toCityName,
                    checkInDate: curTravel.firstDepartTime,
                    checkOutDate: curTravel.firstReturnTime,
                    departTime: curTravel.departTime,
                    returnTime: curTravel.returnTime,
                    applyId: curTravel.id,
                    journeyId: val.journeyId
                };
                const encryptedToBack = aes_encrypt({
                    data: JSON.stringify(queryParams),
                    key: 'params',
                });

                let urlParamsToBack = {
                    params: encryptedToBack,
                    evectionNo: val.evectionNo,
                    evectionType: 2, // 1是个人出差，2是出差单出差
                    evectionRequired: parseInt(this.detail.evectionRequired) || 0,
                    workTravelType: val.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                    journeyType: val.journeyType, // 0 普通单， 1 不限行程单
                    unlimitedJourney: this.unlimitedJourney, //0:限制行程；1：不限行程
                    shareJourneyId: val.shareJourneyId,
                    shareEvectionNo: val.shareEvectionNo,
                }

                this.$router.replace({
                    name: 'admin-hotel-search',
                    query: urlParamsToBack,
                })
            } else {
                // location.reload();
                this.getHotelImg(this.reserveForm.hotelId);
                this.dateRange = [this.searchForm.checkInDate, this.searchForm.checkOutDate];
                this.req_detail(this.searchForm, "init");

                if (Number(this.detail.evectionType) === 1) {
                    this.getHotelInfoAndImage();
                }
            }
        },
    },
    filters: {
        format_star(val) {
            switch (Number(val)) {
                case 20:
                    return "经济型";
                case 25:
                    return "经济型";
                case 30:
                    return "舒适型";
                case 35:
                    return "舒适型";
                case 40:
                    return "高档型";
                case 45:
                    return "高档型";
                case 50:
                    return "豪华型";
                case 55:
                    return "豪华型";
            }
        },
    },
    async activated() {
        await this.refundChangePolicyGet();
        this.$router.afterEach((to,from,next)=>{
            window.scrollTo(0,0)
        })
        let query = this.$route.query
        this.evectionType = query.evectionType
        const key = 'params';
        const params = aes_decrypt({
            data: query.params,
            key,
        });
        query = Object.assign({}, query, JSON.parse(params))
        this.init(query);
        this.getEvectionType();
        await this.canChangeFactor();
        this.setStandard();

        let curTimeList = this.$moment().toArray();
        this.isMorning = curTimeList[3] < 6;
        if (this.isMorning) {
            //凌晨时进入预订页面需要做的事情
            this.initDateForMorning();
        }
    },
    watch: {
        checkInDate(val) {
            const form = this.searchForm;
            if (val) {
                const moment = this.$moment;
                const start = moment(val[0])
                    .add(30, "days")
                    .format("YYYYMMDD");
                const end = moment(val[1])
                    .add(30, "days")
                    .format("YYYYMMDD");

                if (start === end)
                    this.dateRange = [
                        val[0],
                        moment(val[1])
                            .add(1, "days")
                            .format("YYYY-MM-DD"),
                    ];

                if (start < moment(val[1]).format("YYYYMMDD"))
                    this.dateRange = [val[0], moment(start).format("YYYY-MM-DD")];
                form.checkInDate = val[0];
                form.checkOutDate = val[1];
            } else {
                form.checkInDate = "";
                form.checkOutDate = "";
            }
        },
        dateRange() {
            if (this.isMorning) {
                this.getIsTodayMorning();
            }
        }
    },
};
